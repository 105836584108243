<template>
  <v-container>
    <v-row class="justify-center">
      <v-card>
        <v-toolbar color="teal" dark elevation="0">
          <v-toolbar-title>Relatório Bonificações</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-select
            :items="periods"
            outlined
            v-model="periodSelected"
            item-text="periodo"
            item-value="id"
            label="Selecionar Periodo"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block depressed @click="generateReport"
            >Gerar Relatório</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import TeamMixins from "../../mixins/TeamMixins";

export default {
  mixins: [TeamMixins],
  name: "report",
  data: () => {
    return {
      periods: [],
      periodSelected: null,
    };
  },
  created() {
    this.getPeriods();
  },
  methods: {
    async generateReport() {
      try {
        const response = await this.$http.get(`report/${this.periodSelected}`, {
          responseType: "blob",
        });

        let nomearquivo = response.headers["content-disposition"].split("=")[1];

        const blob = new Blob([response.data], {
          type: "application/pdf",
        });
        const url = window.URL.createObjectURL(blob);
        var tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = url;
        tempLink.setAttribute("download", nomearquivo);

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === "undefined") {
          tempLink.setAttribute("target", "_blank");
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        // Fixes "webkit blob resource error 1"
        setTimeout(function () {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(url);
        }, 0);
      } catch (error) {
        this.$toast.error("Erro ao gerar relatório!")
      }
    },
    async getPeriods() {
      try {
        const result = await this.$http.get("saleperiod/finished");
        this.periods = result.data;
      } catch (error) {
        this.$toast.error("Erro ao consultar periodos!")
      }
    },
  },
};
</script>

<style>
</style>