const TeamMixins = {
    methods: {
        async getTeams() {
            try {
                const { data, status } = await this.$http.get("/team");
                if (status == '200') {
                    this.teams = data;
                } else {
                    throw new Error()
                }
            } catch (error) {
                this.$toast.error("Não foi possivel carregar as informações!!!");
            }
        },
        async getGoalsSalesPerson() {
            try {
                this.loading = true
                const { data, status } = await this.$http.get(`/goal/salerperson/team/${this.team.id}`);
                if (status == '200') {
                    this.loading = false
                    this.salesPersons = data;
                } else {
                    throw new Error()
                }
            } catch (error) {
                this.$toast.error("Não foi possivel carregar as informações!!!");
            }
        }
    }

}
export default TeamMixins;